import {chakra} from '@chakra-ui/react'

const Logo = () => {
  return (
    <chakra.svg
      filter="drop-shadow(1px 2px 2px rgb(0 0 0 / 0.1))"
      xmlns="http://www.w3.org/2000/svg"
      width="full"
      height="full"
      viewBox="0 0 16356 1380">
      <path
        id="logo"
        // fill="#e3000f"
        // text-shadow: rgba(0,0,0,.01) 0 0 1px;
        // filter="drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4))"
        fill="red"
        d="M 2594.00,2.00
           C 2594.00,2.00 2864.00,2.00 2864.00,2.00
             2864.00,2.00 2858.25,37.00 2858.25,37.00
             2858.25,37.00 2845.42,110.00 2845.42,110.00
             2845.42,110.00 2812.42,297.00 2812.42,297.00
             2812.42,297.00 2746.58,671.00 2746.58,671.00
             2746.58,671.00 2716.58,841.00 2716.58,841.00
             2716.58,841.00 2699.92,935.00 2699.92,935.00
             2699.92,935.00 2671.58,1096.00 2671.58,1096.00
             2666.16,1126.03 2658.95,1162.39 2673.27,1191.00
             2687.08,1218.60 2719.84,1226.03 2748.00,1228.09
             2748.00,1228.09 2760.00,1229.00 2760.00,1229.00
             2760.00,1229.00 2854.00,1229.00 2854.00,1229.00
             2854.00,1229.00 2850.39,1248.00 2850.39,1248.00
             2850.39,1248.00 2842.58,1291.00 2842.58,1291.00
             2842.58,1291.00 2834.00,1340.00 2834.00,1340.00
             2834.00,1340.00 2742.00,1340.00 2742.00,1340.00
             2742.00,1340.00 2676.00,1340.00 2676.00,1340.00
             2676.00,1340.00 2633.00,1340.00 2633.00,1340.00
             2568.63,1340.00 2485.45,1339.85 2438.09,1289.00
             2431.04,1281.43 2424.85,1272.98 2419.72,1264.00
             2412.70,1251.73 2407.50,1239.70 2403.88,1226.00
             2397.75,1202.79 2395.72,1178.94 2396.00,1155.00
             2396.00,1155.00 2396.96,1144.00 2396.96,1144.00
             2398.73,1103.39 2409.64,1052.95 2416.75,1012.00
             2416.75,1012.00 2459.75,767.00 2459.75,767.00
             2459.75,767.00 2516.58,443.00 2516.58,443.00
             2516.58,443.00 2546.58,272.00 2546.58,272.00
             2546.58,272.00 2594.00,2.00 2594.00,2.00 Z
           M 3313.00,2.00
           C 3313.00,2.00 3584.00,2.00 3584.00,2.00
             3584.00,2.00 3577.58,37.00 3577.58,37.00
             3577.58,37.00 3565.75,104.00 3565.75,104.00
             3565.75,104.00 3532.58,292.00 3532.58,292.00
             3532.58,292.00 3449.42,763.00 3449.42,763.00
             3449.42,763.00 3417.39,945.00 3417.39,945.00
             3417.39,945.00 3389.75,1102.00 3389.75,1102.00
             3384.69,1131.12 3378.52,1164.49 3393.12,1192.00
             3407.25,1218.62 3439.35,1226.07 3467.00,1228.09
             3467.00,1228.09 3479.00,1229.00 3479.00,1229.00
             3479.00,1229.00 3573.00,1229.00 3573.00,1229.00
             3573.00,1229.00 3561.20,1294.00 3561.20,1294.00
             3561.20,1294.00 3553.00,1340.00 3553.00,1340.00
             3553.00,1340.00 3318.00,1340.00 3318.00,1340.00
             3318.00,1340.00 3303.00,1339.00 3303.00,1339.00
             3256.13,1338.46 3198.33,1327.86 3163.00,1294.91
             3146.53,1279.55 3133.39,1257.25 3126.08,1236.00
             3120.56,1219.97 3117.77,1202.82 3116.04,1186.00
             3116.04,1186.00 3116.04,1176.00 3116.04,1176.00
             3116.04,1176.00 3115.00,1161.00 3115.00,1161.00
             3115.00,1161.00 3115.96,1151.00 3115.96,1151.00
             3115.96,1151.00 3115.96,1141.00 3115.96,1141.00
             3115.96,1141.00 3121.92,1092.00 3121.92,1092.00
             3121.92,1092.00 3134.75,1019.00 3134.75,1019.00
             3134.75,1019.00 3174.58,792.00 3174.58,792.00
             3174.58,792.00 3207.42,604.00 3207.42,604.00
             3207.42,604.00 3267.25,263.00 3267.25,263.00
             3267.25,263.00 3297.42,91.00 3297.42,91.00
             3297.42,91.00 3307.42,34.00 3307.42,34.00
             3307.42,34.00 3313.00,2.00 3313.00,2.00 Z
           M 12196.00,2.00
           C 12196.00,2.00 12466.00,2.00 12466.00,2.00
             12466.00,2.00 12460.25,37.00 12460.25,37.00
             12460.25,37.00 12447.42,110.00 12447.42,110.00
             12447.42,110.00 12411.42,314.00 12411.42,314.00
             12411.42,314.00 12332.42,762.00 12332.42,762.00
             12332.42,762.00 12301.61,937.00 12301.61,937.00
             12301.61,937.00 12278.75,1067.00 12278.75,1067.00
             12273.70,1096.10 12266.66,1125.34 12267.00,1155.00
             12267.08,1161.26 12268.72,1171.95 12270.43,1178.00
             12279.39,1209.76 12303.69,1221.28 12334.00,1226.25
             12341.73,1227.51 12354.30,1228.99 12362.00,1229.00
             12362.00,1229.00 12456.00,1229.00 12456.00,1229.00
             12456.00,1229.00 12442.58,1302.00 12442.58,1302.00
             12442.58,1302.00 12436.00,1340.00 12436.00,1340.00
             12436.00,1340.00 12278.00,1340.00 12278.00,1340.00
             12278.00,1340.00 12199.00,1340.00 12199.00,1340.00
             12199.00,1340.00 12184.00,1339.00 12184.00,1339.00
             12184.00,1339.00 12179.00,1339.00 12179.00,1339.00
             12179.00,1339.00 12148.00,1336.15 12148.00,1336.15
             12113.57,1332.04 12075.55,1320.81 12049.00,1297.71
             12031.03,1282.08 12017.64,1260.31 12009.69,1238.00
             12000.56,1212.35 11997.96,1185.04 11998.00,1158.00
             11998.00,1158.00 11999.00,1139.00 11999.00,1139.00
             11999.19,1122.82 12004.57,1092.66 12007.58,1076.00
             12007.58,1076.00 12021.58,996.00 12021.58,996.00
             12021.58,996.00 12064.58,751.00 12064.58,751.00
             12064.58,751.00 12167.58,164.00 12167.58,164.00
             12167.58,164.00 12196.00,2.00 12196.00,2.00 Z
           M 12915.00,2.00
           C 12915.00,2.00 13186.00,2.00 13186.00,2.00
             13186.00,2.00 13179.58,37.00 13179.58,37.00
             13179.58,37.00 13164.58,122.00 13164.58,122.00
             13164.58,122.00 13124.75,348.00 13124.75,348.00
             13124.75,348.00 13081.42,593.00 13081.42,593.00
             13081.42,593.00 13048.58,780.00 13048.58,780.00
             13048.58,780.00 13019.75,943.00 13019.75,943.00
             13019.75,943.00 12997.58,1069.00 12997.58,1069.00
             12993.43,1092.02 12985.97,1128.70 12986.00,1151.00
             12986.01,1160.22 12988.00,1174.33 12991.09,1183.00
             13003.14,1216.86 13036.76,1225.73 13069.00,1228.09
             13069.00,1228.09 13081.00,1229.00 13081.00,1229.00
             13081.00,1229.00 13175.00,1229.00 13175.00,1229.00
             13175.00,1229.00 13169.42,1260.00 13169.42,1260.00
             13169.42,1260.00 13159.75,1313.00 13159.75,1313.00
             13159.75,1313.00 13155.00,1340.00 13155.00,1340.00
             13155.00,1340.00 12998.00,1340.00 12998.00,1340.00
             12998.00,1340.00 12919.00,1340.00 12919.00,1340.00
             12919.00,1340.00 12904.00,1339.00 12904.00,1339.00
             12904.00,1339.00 12897.00,1339.00 12897.00,1339.00
             12897.00,1339.00 12876.00,1337.16 12876.00,1337.16
             12837.02,1332.99 12795.80,1322.79 12766.00,1295.83
             12751.24,1282.48 12736.92,1259.74 12730.05,1241.00
             12723.65,1223.53 12718.03,1198.59 12718.00,1180.00
             12718.00,1180.00 12717.03,1163.00 12717.03,1163.00
             12717.03,1163.00 12717.96,1154.00 12717.96,1154.00
             12717.96,1154.00 12717.96,1142.00 12717.96,1142.00
             12717.96,1142.00 12718.91,1132.00 12718.91,1132.00
             12721.40,1097.89 12729.82,1059.13 12735.75,1025.00
             12735.75,1025.00 12772.75,814.00 12772.75,814.00
             12772.75,814.00 12836.58,450.00 12836.58,450.00
             12836.58,450.00 12866.42,279.00 12866.42,279.00
             12866.42,279.00 12896.42,108.00 12896.42,108.00
             12896.42,108.00 12909.25,35.00 12909.25,35.00
             12909.25,35.00 12915.00,2.00 12915.00,2.00 Z
           M 221.00,91.00
           C 221.00,91.00 737.00,91.00 737.00,91.00
             737.00,91.00 816.00,91.00 816.00,91.00
             816.00,91.00 868.00,91.00 868.00,91.00
             868.00,91.00 880.00,92.00 880.00,92.00
             880.00,92.00 888.00,92.00 888.00,92.00
             906.00,93.32 924.30,95.91 942.00,99.45
             986.72,108.39 1029.32,126.71 1067.00,152.34
             1088.80,167.17 1109.37,186.21 1125.58,207.00
             1160.97,252.41 1182.33,312.31 1183.00,370.00
             1183.00,370.00 1183.97,382.00 1183.97,382.00
             1183.97,382.00 1183.00,394.00 1183.00,394.00
             1182.77,413.89 1179.33,433.81 1174.15,453.00
             1157.78,513.74 1125.22,565.63 1076.00,605.20
             1033.34,639.49 984.20,661.52 930.00,669.27
             930.00,669.27 904.00,672.00 904.00,672.00
             904.00,672.00 882.00,673.06 882.00,673.06
             879.67,673.27 875.57,674.42 877.17,677.70
             878.49,680.42 883.47,679.99 886.00,680.00
             886.00,680.00 894.00,680.00 894.00,680.00
             913.39,680.23 937.42,685.70 956.00,691.28
             974.18,696.74 992.89,705.40 1009.00,715.42
             1023.59,724.51 1040.90,737.80 1052.83,750.09
             1099.62,798.27 1121.59,859.19 1128.17,925.00
             1128.17,925.00 1130.00,949.00 1130.00,949.00
             1130.00,949.00 1130.00,981.00 1130.00,981.00
             1130.00,981.00 1129.09,991.00 1129.09,991.00
             1129.09,991.00 1124.41,1031.00 1124.41,1031.00
             1108.37,1126.58 1056.63,1204.73 976.00,1258.49
             908.86,1303.25 827.96,1328.09 748.00,1335.83
             748.00,1335.83 692.00,1339.00 692.00,1339.00
             692.00,1339.00 675.00,1340.00 675.00,1340.00
             675.00,1340.00 556.00,1340.00 556.00,1340.00
             556.00,1340.00 2.00,1340.00 2.00,1340.00
             2.00,1340.00 7.75,1307.00 7.75,1307.00
             7.75,1307.00 20.75,1233.00 20.75,1233.00
             20.75,1233.00 59.58,1012.00 59.58,1012.00
             59.58,1012.00 162.42,426.00 162.42,426.00
             162.42,426.00 201.42,204.00 201.42,204.00
             201.42,204.00 214.42,130.00 214.42,130.00
             214.42,130.00 221.00,91.00 221.00,91.00 Z
           M 9823.00,91.00
           C 9823.00,91.00 10339.00,91.00 10339.00,91.00
             10339.00,91.00 10418.00,91.00 10418.00,91.00
             10418.00,91.00 10470.00,91.00 10470.00,91.00
             10470.00,91.00 10482.00,92.00 10482.00,92.00
             10482.00,92.00 10490.00,92.00 10490.00,92.00
             10508.00,93.32 10526.30,95.91 10544.00,99.45
             10588.73,108.40 10631.31,126.70 10669.00,152.34
             10692.82,168.55 10713.25,188.15 10730.77,211.00
             10752.70,239.61 10767.65,273.13 10776.37,308.00
             10780.21,323.34 10784.92,348.42 10785.00,364.00
             10785.00,364.00 10785.99,380.00 10785.99,380.00
             10785.99,380.00 10785.00,400.00 10785.00,400.00
             10784.81,416.00 10779.55,441.42 10775.14,457.00
             10758.83,514.56 10725.92,567.02 10679.00,604.55
             10635.07,639.69 10582.13,663.77 10526.00,670.05
             10526.00,670.05 10489.00,672.91 10489.00,672.91
             10486.62,673.08 10476.61,672.46 10479.17,677.70
             10480.49,680.42 10485.47,679.99 10488.00,680.00
             10488.00,680.00 10496.00,680.00 10496.00,680.00
             10514.59,680.22 10538.08,685.46 10556.00,690.58
             10573.35,695.53 10592.50,704.38 10608.00,713.60
             10624.57,723.46 10640.09,735.75 10653.91,749.17
             10695.96,790.00 10720.16,849.65 10728.13,907.00
             10728.13,907.00 10732.00,949.00 10732.00,949.00
             10732.00,949.00 10732.00,981.00 10732.00,981.00
             10732.00,981.00 10731.09,991.00 10731.09,991.00
             10731.09,991.00 10726.41,1031.00 10726.41,1031.00
             10710.39,1126.46 10658.52,1204.80 10578.00,1258.49
             10514.54,1300.79 10439.41,1325.14 10364.00,1334.15
             10364.00,1334.15 10318.00,1338.04 10318.00,1338.04
             10318.00,1338.04 10305.00,1339.04 10305.00,1339.04
             10305.00,1339.04 10289.00,1339.04 10289.00,1339.04
             10289.00,1339.04 10277.00,1340.00 10277.00,1340.00
             10277.00,1340.00 10158.00,1340.00 10158.00,1340.00
             10158.00,1340.00 9604.00,1340.00 9604.00,1340.00
             9604.00,1340.00 9609.92,1306.00 9609.92,1306.00
             9609.92,1306.00 9625.58,1217.00 9625.58,1217.00
             9625.58,1217.00 9661.58,1012.00 9661.58,1012.00
             9661.58,1012.00 9764.42,426.00 9764.42,426.00
             9764.42,426.00 9803.42,204.00 9803.42,204.00
             9803.42,204.00 9816.42,130.00 9816.42,130.00
             9816.42,130.00 9823.00,91.00 9823.00,91.00 Z
           M 7851.00,681.00
           C 7851.00,681.00 7822.58,644.00 7822.58,644.00
             7811.70,630.02 7801.51,617.59 7792.86,602.00
             7777.65,574.59 7766.75,538.74 7760.60,508.00
             7757.36,491.80 7754.03,471.44 7754.00,455.00
             7754.00,455.00 7754.00,438.00 7754.00,438.00
             7754.00,438.00 7754.91,428.00 7754.91,428.00
             7756.85,400.29 7761.88,376.35 7770.67,350.00
             7786.84,301.49 7815.33,256.52 7851.01,220.00
             7896.62,173.33 7963.88,135.47 8027.00,118.88
             8049.91,112.86 8073.38,108.64 8097.00,106.91
             8097.00,106.91 8107.00,106.00 8107.00,106.00
             8107.00,106.00 8126.00,105.00 8126.00,105.00
             8126.00,105.00 8150.00,105.00 8150.00,105.00
             8150.00,105.00 8171.00,106.00 8171.00,106.00
             8171.00,106.00 8181.00,106.91 8181.00,106.91
             8181.00,106.91 8217.00,110.73 8217.00,110.73
             8256.87,116.48 8296.48,128.10 8332.00,147.31
             8356.26,160.43 8378.33,177.51 8396.83,198.00
             8441.72,247.70 8453.60,317.12 8437.63,381.00
             8429.34,414.14 8412.82,446.23 8394.68,475.00
             8375.50,505.43 8366.23,519.85 8338.00,543.73
             8310.72,566.80 8272.52,589.00 8241.00,606.30
             8241.00,606.30 8183.00,637.25 8183.00,637.25
             8183.00,637.25 8161.00,648.00 8161.00,648.00
             8161.00,648.00 8187.58,683.00 8187.58,683.00
             8187.58,683.00 8232.35,741.00 8232.35,741.00
             8232.35,741.00 8365.79,914.00 8365.79,914.00
             8365.79,914.00 8415.13,978.00 8415.13,978.00
             8415.13,978.00 8428.35,995.00 8428.35,995.00
             8429.66,996.67 8431.84,999.95 8434.09,1000.24
             8437.50,1000.68 8444.56,992.43 8447.00,990.00
             8447.00,990.00 8480.02,956.00 8480.02,956.00
             8497.76,936.97 8510.02,913.23 8519.05,889.00
             8537.48,839.56 8542.00,787.31 8542.00,735.00
             8542.00,735.00 8646.00,735.00 8646.00,735.00
             8646.00,735.00 8645.00,762.00 8645.00,762.00
             8645.00,762.00 8644.00,774.00 8644.00,774.00
             8644.00,774.00 8644.00,783.00 8644.00,783.00
             8644.00,783.00 8637.57,842.00 8637.57,842.00
             8629.03,892.90 8611.84,936.23 8584.58,980.00
             8566.63,1008.81 8545.98,1034.02 8522.00,1058.00
             8522.00,1058.00 8503.00,1076.58 8503.00,1076.58
             8500.72,1078.56 8496.45,1081.55 8496.91,1085.00
             8497.18,1087.01 8500.07,1090.34 8501.35,1092.00
             8501.35,1092.00 8512.13,1106.00 8512.13,1106.00
             8512.13,1106.00 8555.58,1162.00 8555.58,1162.00
             8555.58,1162.00 8651.42,1286.00 8651.42,1286.00
             8651.42,1286.00 8690.88,1337.00 8690.88,1337.00
             8690.88,1337.00 8709.00,1361.00 8709.00,1361.00
             8709.00,1361.00 8383.00,1361.00 8383.00,1361.00
             8381.15,1361.00 8377.96,1361.14 8376.30,1360.43
             8373.23,1359.12 8362.81,1344.54 8360.13,1341.00
             8360.13,1341.00 8313.42,1280.00 8313.42,1280.00
             8313.42,1280.00 8300.37,1263.00 8300.37,1263.00
             8298.93,1261.09 8296.44,1257.17 8293.91,1256.87
             8291.44,1256.59 8286.15,1260.86 8284.00,1262.34
             8284.00,1262.34 8261.00,1278.33 8261.00,1278.33
             8227.77,1300.50 8207.10,1313.75 8170.00,1329.72
             8102.23,1358.89 8017.75,1377.14 7944.00,1378.00
             7944.00,1378.00 7930.00,1379.00 7930.00,1379.00
             7930.00,1379.00 7897.00,1379.00 7897.00,1379.00
             7897.00,1379.00 7885.00,1378.04 7885.00,1378.04
             7885.00,1378.04 7874.00,1378.04 7874.00,1378.04
             7874.00,1378.04 7864.00,1377.09 7864.00,1377.09
             7864.00,1377.09 7817.00,1372.27 7817.00,1372.27
             7755.45,1363.40 7687.64,1339.26 7638.00,1301.35
             7604.84,1276.02 7577.06,1243.39 7558.37,1206.00
             7540.37,1170.00 7531.64,1129.87 7528.91,1090.00
             7528.91,1090.00 7528.00,1079.00 7528.00,1079.00
             7527.89,1055.45 7529.68,1032.25 7533.59,1009.00
             7537.84,983.65 7544.45,958.55 7554.86,935.00
             7574.50,890.58 7603.54,853.77 7638.00,819.99
             7688.88,770.10 7748.47,731.52 7812.00,699.75
             7812.00,699.75 7851.00,681.00 7851.00,681.00 Z
           M 8136.00,216.10
           C 8119.67,218.68 8104.84,221.51 8090.00,229.32
             8053.01,248.78 8026.44,287.76 8016.88,328.00
             8011.90,348.98 8011.97,362.87 8012.00,384.00
             8012.00,384.00 8013.04,396.00 8013.04,396.00
             8013.04,396.00 8013.04,405.00 8013.04,405.00
             8015.12,425.48 8017.97,440.96 8026.15,460.00
             8036.39,483.85 8060.44,514.56 8076.37,536.00
             8076.37,536.00 8089.42,554.00 8089.42,554.00
             8091.07,556.22 8092.92,559.16 8096.04,559.05
             8098.35,558.97 8112.63,550.93 8116.00,549.25
             8116.00,549.25 8166.00,522.78 8166.00,522.78
             8217.87,495.40 8278.87,450.99 8301.55,395.00
             8306.67,382.36 8311.63,361.61 8311.99,348.00
             8311.99,348.00 8312.92,335.00 8312.92,335.00
             8312.92,335.00 8312.17,328.00 8312.17,328.00
             8311.00,315.74 8310.43,307.85 8305.94,296.00
             8302.48,286.89 8299.15,281.01 8293.65,273.00
             8270.59,239.41 8228.19,222.53 8189.00,217.85
             8189.00,217.85 8169.00,216.10 8169.00,216.10
             8169.00,216.10 8141.00,216.10 8141.00,216.10
             8141.00,216.10 8136.00,216.10 8136.00,216.10 Z
           M 490.00,612.00
           C 490.00,612.00 663.00,612.00 663.00,612.00
             673.69,611.98 686.61,609.96 697.00,607.37
             768.55,589.57 810.53,522.15 825.58,454.00
             829.10,438.05 830.98,422.24 832.09,406.00
             832.09,406.00 833.00,396.00 833.00,396.00
             833.42,359.57 831.01,324.29 817.40,290.00
             812.22,276.95 806.70,264.83 797.56,254.00
             781.22,234.63 754.69,226.29 730.00,226.00
             730.00,226.00 557.00,226.00 557.00,226.00
             557.00,226.00 490.00,612.00 490.00,612.00 Z
           M 10092.00,612.00
           C 10092.00,612.00 10265.00,612.00 10265.00,612.00
             10275.69,611.98 10288.61,609.96 10299.00,607.37
             10388.72,585.04 10428.34,490.03 10434.09,406.00
             10434.09,406.00 10435.00,396.00 10435.00,396.00
             10435.42,359.69 10432.96,324.17 10419.40,290.00
             10414.34,277.24 10408.47,264.56 10399.56,254.00
             10383.22,234.63 10356.69,226.29 10332.00,226.00
             10332.00,226.00 10159.00,226.00 10159.00,226.00
             10159.00,226.00 10149.25,282.00 10149.25,282.00
             10149.25,282.00 10132.42,379.00 10132.42,379.00
             10132.42,379.00 10092.00,612.00 10092.00,612.00 Z
           M 4213.00,425.21
           C 4213.00,425.21 4245.00,425.21 4245.00,425.21
             4245.00,425.21 4259.00,426.00 4259.00,426.00
             4259.00,426.00 4312.00,429.84 4312.00,429.84
             4411.24,440.45 4514.24,480.45 4587.00,550.04
             4642.96,603.56 4681.86,678.95 4695.58,755.00
             4698.47,771.06 4702.98,803.28 4703.00,819.00
             4703.00,819.00 4704.00,840.00 4704.00,840.00
             4704.00,840.00 4704.00,856.00 4704.00,856.00
             4704.00,856.00 4703.00,875.00 4703.00,875.00
             4702.94,888.42 4698.90,915.25 4696.42,929.00
             4689.16,969.27 4676.77,1008.80 4659.69,1046.00
             4613.25,1147.14 4537.23,1221.72 4440.00,1274.31
             4405.17,1293.15 4368.27,1308.50 4330.00,1318.85
             4291.14,1329.36 4250.20,1336.29 4210.00,1338.04
             4210.00,1338.04 4199.00,1339.00 4199.00,1339.00
             4199.00,1339.00 4186.00,1339.00 4186.00,1339.00
             4186.00,1339.00 4171.00,1340.00 4171.00,1340.00
             4171.00,1340.00 4157.00,1340.00 4157.00,1340.00
             4157.00,1340.00 4146.00,1339.02 4146.00,1339.02
             4146.00,1339.02 4130.00,1339.02 4130.00,1339.02
             4130.00,1339.02 4119.00,1338.04 4119.00,1338.04
             4090.86,1336.81 4062.65,1332.93 4035.00,1327.40
             3949.25,1310.25 3866.27,1274.82 3803.00,1212.99
             3803.00,1212.99 3791.91,1201.00 3791.91,1201.00
             3748.07,1153.70 3717.02,1096.57 3701.37,1034.00
             3690.59,990.85 3687.49,946.31 3688.00,902.00
             3688.00,902.00 3688.96,891.00 3688.96,891.00
             3692.33,813.67 3718.75,733.64 3759.42,668.00
             3776.84,639.88 3796.40,615.18 3818.91,591.00
             3818.91,591.00 3830.00,579.01 3830.00,579.01
             3899.93,510.67 3990.12,463.38 4085.00,440.88
             4114.86,433.80 4145.52,430.07 4176.00,427.04
             4176.00,427.04 4185.00,427.04 4185.00,427.04
             4185.00,427.04 4196.00,426.00 4196.00,426.00
             4201.70,425.97 4207.34,426.23 4213.00,425.21 Z
           M 13815.00,425.21
           C 13815.00,425.21 13847.00,425.21 13847.00,425.21
             13847.00,425.21 13861.00,426.00 13861.00,426.00
             13881.12,426.23 13901.04,428.12 13921.00,430.72
             14019.18,443.53 14116.55,480.74 14189.00,550.04
             14252.38,610.66 14290.94,694.51 14301.72,781.00
             14301.72,781.00 14305.00,821.00 14305.00,821.00
             14305.00,821.00 14306.00,838.00 14306.00,838.00
             14306.00,838.00 14306.00,857.00 14306.00,857.00
             14306.00,857.00 14305.00,873.00 14305.00,873.00
             14304.97,887.06 14301.80,909.80 14299.41,924.00
             14292.49,965.28 14279.15,1007.98 14261.69,1046.00
             14215.92,1145.69 14139.90,1221.33 14044.00,1273.31
             14008.52,1292.54 13971.00,1308.30 13932.00,1318.85
             13893.14,1329.36 13852.20,1336.29 13812.00,1338.04
             13812.00,1338.04 13801.00,1339.00 13801.00,1339.00
             13801.00,1339.00 13788.00,1339.00 13788.00,1339.00
             13788.00,1339.00 13773.00,1340.00 13773.00,1340.00
             13773.00,1340.00 13759.00,1340.00 13759.00,1340.00
             13759.00,1340.00 13748.00,1339.02 13748.00,1339.02
             13748.00,1339.02 13732.00,1339.02 13732.00,1339.02
             13732.00,1339.02 13721.00,1338.04 13721.00,1338.04
             13692.86,1336.81 13664.65,1332.93 13637.00,1327.40
             13551.25,1310.25 13468.27,1274.82 13405.00,1212.99
             13405.00,1212.99 13393.91,1201.00 13393.91,1201.00
             13346.15,1149.47 13315.54,1089.42 13300.42,1021.00
             13291.92,982.50 13289.54,942.31 13290.00,903.00
             13290.00,903.00 13290.96,892.00 13290.96,892.00
             13294.32,814.75 13320.60,733.56 13361.42,668.00
             13379.06,639.67 13398.19,615.40 13420.91,591.00
             13420.91,591.00 13432.00,579.01 13432.00,579.01
             13495.04,517.41 13581.61,468.33 13667.00,445.88
             13699.79,437.27 13733.27,431.43 13767.00,428.17
             13767.00,428.17 13787.00,426.91 13787.00,426.91
             13787.00,426.91 13798.00,426.00 13798.00,426.00
             13803.70,425.97 13809.34,426.23 13815.00,425.21 Z
           M 1322.00,1340.00
           C 1324.22,1332.23 1330.91,1321.42 1334.74,1314.00
             1334.74,1314.00 1360.74,1264.00 1360.74,1264.00
             1360.74,1264.00 1440.74,1110.00 1440.74,1110.00
             1440.74,1110.00 1633.26,739.00 1633.26,739.00
             1633.26,739.00 1691.74,627.00 1691.74,627.00
             1691.74,627.00 1731.22,551.00 1731.22,551.00
             1741.46,531.59 1750.63,510.90 1763.43,493.00
             1786.20,461.13 1812.11,439.93 1851.00,432.00
             1857.05,430.77 1868.98,429.07 1875.00,429.00
             1895.59,428.76 1913.00,428.83 1933.00,435.02
             1965.90,445.22 1990.00,469.57 2001.31,502.00
             2005.65,514.44 2010.77,547.30 2013.42,562.00
             2013.42,562.00 2028.25,644.00 2028.25,644.00
             2028.25,644.00 2094.58,1011.00 2094.58,1011.00
             2094.58,1011.00 2154.00,1340.00 2154.00,1340.00
             2154.00,1340.00 1892.00,1340.00 1892.00,1340.00
             1892.00,1340.00 1883.08,1285.00 1883.08,1285.00
             1883.08,1285.00 1866.08,1181.00 1866.08,1181.00
             1866.08,1181.00 1860.73,1148.00 1860.73,1148.00
             1860.73,1148.00 1857.41,1134.60 1857.41,1134.60
             1857.41,1134.60 1849.00,1133.00 1849.00,1133.00
             1849.00,1133.00 1546.00,1133.00 1546.00,1133.00
             1546.00,1133.00 1537.39,1134.60 1537.39,1134.60
             1537.39,1134.60 1530.75,1146.00 1530.75,1146.00
             1530.75,1146.00 1517.75,1172.00 1517.75,1172.00
             1517.75,1172.00 1478.26,1249.00 1478.26,1249.00
             1478.26,1249.00 1454.74,1296.00 1454.74,1296.00
             1454.74,1296.00 1439.75,1325.00 1439.75,1325.00
             1439.75,1325.00 1432.37,1338.40 1432.37,1338.40
             1432.37,1338.40 1424.00,1340.00 1424.00,1340.00
             1424.00,1340.00 1322.00,1340.00 1322.00,1340.00 Z
           M 10924.00,1340.00
           C 10926.19,1332.34 10932.03,1323.20 10935.74,1316.00
             10935.74,1316.00 10962.74,1264.00 10962.74,1264.00
             10962.74,1264.00 11041.74,1112.00 11041.74,1112.00
             11041.74,1112.00 11221.26,766.00 11221.26,766.00
             11221.26,766.00 11274.26,664.00 11274.26,664.00
             11274.26,664.00 11293.75,627.00 11293.75,627.00
             11293.75,627.00 11332.22,553.00 11332.22,553.00
             11342.73,533.08 11352.32,511.33 11365.43,493.00
             11388.14,461.22 11414.22,439.91 11453.00,432.00
             11459.05,430.77 11470.98,429.07 11477.00,429.00
             11497.92,428.76 11514.63,428.71 11535.00,435.02
             11567.94,445.23 11591.98,469.54 11603.31,502.00
             11607.59,514.29 11611.89,541.95 11614.42,556.00
             11614.42,556.00 11630.25,644.00 11630.25,644.00
             11630.25,644.00 11687.58,961.00 11687.58,961.00
             11687.58,961.00 11735.58,1227.00 11735.58,1227.00
             11735.58,1227.00 11756.00,1340.00 11756.00,1340.00
             11756.00,1340.00 11494.00,1340.00 11494.00,1340.00
             11494.00,1340.00 11487.27,1298.00 11487.27,1298.00
             11487.27,1298.00 11468.08,1181.00 11468.08,1181.00
             11468.08,1181.00 11462.75,1148.00 11462.75,1148.00
             11462.75,1148.00 11459.41,1134.60 11459.41,1134.60
             11459.41,1134.60 11451.00,1133.00 11451.00,1133.00
             11451.00,1133.00 11148.00,1133.00 11148.00,1133.00
             11148.00,1133.00 11140.33,1134.02 11140.33,1134.02
             11140.33,1134.02 11133.25,1145.00 11133.25,1145.00
             11133.25,1145.00 11120.25,1171.00 11120.25,1171.00
             11120.25,1171.00 11057.25,1295.00 11057.25,1295.00
             11057.25,1295.00 11042.25,1324.00 11042.25,1324.00
             11042.25,1324.00 11034.37,1338.40 11034.37,1338.40
             11034.37,1338.40 11026.00,1340.00 11026.00,1340.00
             11026.00,1340.00 10924.00,1340.00 10924.00,1340.00 Z
           M 4924.00,1333.00
           C 4924.00,1333.00 4935.75,1266.00 4935.75,1266.00
             4935.75,1266.00 4963.58,1110.00 4963.58,1110.00
             4963.58,1110.00 5048.42,633.00 5048.42,633.00
             5059.47,571.74 5065.72,499.72 5125.00,464.45
             5135.25,458.35 5147.53,453.11 5159.00,449.86
             5170.76,446.53 5188.86,443.15 5201.00,443.00
             5220.87,442.77 5237.56,443.61 5257.00,448.47
             5273.10,452.50 5289.60,459.19 5304.00,467.43
             5335.62,485.53 5359.55,516.83 5375.63,549.00
             5375.63,549.00 5388.30,577.00 5388.30,577.00
             5388.30,577.00 5407.86,621.00 5407.86,621.00
             5407.86,621.00 5471.14,763.00 5471.14,763.00
             5471.14,763.00 5503.14,835.00 5503.14,835.00
             5503.14,835.00 5522.25,878.00 5522.25,878.00
             5529.15,892.49 5537.04,911.53 5556.00,911.99
             5567.89,912.27 5570.68,908.83 5574.33,898.00
             5574.33,898.00 5577.52,888.00 5577.52,888.00
             5577.52,888.00 5592.08,807.00 5592.08,807.00
             5592.08,807.00 5623.58,629.00 5623.58,629.00
             5623.58,629.00 5654.00,457.00 5654.00,457.00
             5654.00,457.00 5764.00,457.00 5764.00,457.00
             5764.00,457.00 5759.08,487.00 5759.08,487.00
             5759.08,487.00 5748.42,548.00 5748.42,548.00
             5748.42,548.00 5722.25,698.00 5722.25,698.00
             5722.25,698.00 5670.42,995.00 5670.42,995.00
             5670.42,995.00 5645.00,1141.00 5645.00,1141.00
             5637.32,1180.68 5631.73,1231.82 5619.33,1269.00
             5613.33,1287.01 5602.53,1310.62 5587.00,1322.08
             5569.94,1334.66 5551.49,1337.68 5531.00,1339.09
             5531.00,1339.09 5522.00,1339.99 5522.00,1339.99
             5522.00,1339.99 5510.00,1338.96 5510.00,1338.96
             5510.00,1338.96 5501.00,1338.96 5501.00,1338.96
             5483.25,1337.13 5464.67,1331.91 5450.00,1321.56
             5439.98,1314.50 5432.29,1304.83 5425.15,1295.00
             5408.88,1272.60 5399.22,1250.06 5388.14,1225.00
             5388.14,1225.00 5325.31,1085.00 5325.31,1085.00
             5325.31,1085.00 5248.30,913.00 5248.30,913.00
             5248.30,913.00 5216.86,843.00 5216.86,843.00
             5216.86,843.00 5194.24,793.00 5194.24,793.00
             5187.36,779.47 5173.37,760.85 5156.00,763.44
             5136.63,766.34 5131.38,786.74 5127.65,803.00
             5127.65,803.00 5119.58,848.00 5119.58,848.00
             5119.58,848.00 5104.25,935.00 5104.25,935.00
             5104.25,935.00 5050.59,1240.00 5050.59,1240.00
             5050.59,1240.00 5038.08,1311.00 5038.08,1311.00
             5038.08,1311.00 5035.42,1326.00 5035.42,1326.00
             5035.07,1327.86 5034.83,1330.75 5033.26,1332.01
             5031.71,1333.26 5027.95,1333.00 5026.00,1333.00
             5026.00,1333.00 4924.00,1333.00 4924.00,1333.00 Z
           M 14526.00,1333.00
           C 14526.00,1333.00 14536.75,1272.00 14536.75,1272.00
             14536.75,1272.00 14562.58,1127.00 14562.58,1127.00
             14562.58,1127.00 14611.42,852.00 14611.42,852.00
             14611.42,852.00 14648.75,643.00 14648.75,643.00
             14648.75,643.00 14656.58,599.00 14656.58,599.00
             14666.35,544.81 14678.13,490.96 14731.00,462.31
             14742.62,456.01 14753.17,451.68 14766.00,448.47
             14785.90,443.50 14800.70,442.77 14821.00,443.00
             14821.00,443.00 14831.00,443.92 14831.00,443.92
             14871.01,447.12 14909.17,464.04 14937.96,492.09
             14947.08,500.97 14958.33,516.16 14965.31,527.00
             14979.46,548.99 14994.83,587.95 15006.31,613.00
             15006.31,613.00 15077.14,772.00 15077.14,772.00
             15077.14,772.00 15125.23,880.00 15125.23,880.00
             15131.62,894.00 15140.22,911.55 15158.00,911.99
             15161.83,912.08 15165.60,912.12 15168.96,909.99
             15176.66,905.13 15180.43,884.79 15181.92,876.00
             15181.92,876.00 15204.08,751.00 15204.08,751.00
             15204.08,751.00 15224.58,635.00 15224.58,635.00
             15224.58,635.00 15256.00,457.00 15256.00,457.00
             15256.00,457.00 15366.00,457.00 15366.00,457.00
             15366.00,457.00 15361.42,485.00 15361.42,485.00
             15361.42,485.00 15353.25,532.00 15353.25,532.00
             15353.25,532.00 15327.42,680.00 15327.42,680.00
             15327.42,680.00 15294.58,869.00 15294.58,869.00
             15294.58,869.00 15246.92,1142.00 15246.92,1142.00
             15240.98,1177.20 15231.94,1237.20 15221.33,1269.00
             15215.18,1287.46 15204.77,1310.16 15189.00,1322.10
             15172.65,1334.48 15152.85,1337.72 15133.00,1339.09
             15133.00,1339.09 15124.00,1339.99 15124.00,1339.99
             15124.00,1339.99 15112.00,1338.96 15112.00,1338.96
             15112.00,1338.96 15103.00,1338.96 15103.00,1338.96
             15088.64,1337.46 15070.41,1332.94 15058.00,1325.55
             15045.20,1317.92 15037.38,1308.76 15028.63,1297.00
             15011.49,1273.96 15001.61,1250.94 14990.14,1225.00
             14990.14,1225.00 14850.30,913.00 14850.30,913.00
             14850.30,913.00 14815.31,835.00 14815.31,835.00
             14815.31,835.00 14796.24,793.00 14796.24,793.00
             14789.36,779.47 14775.37,760.85 14758.00,763.44
             14739.03,766.28 14733.16,786.86 14729.66,803.00
             14729.66,803.00 14720.58,854.00 14720.58,854.00
             14720.58,854.00 14706.25,935.00 14706.25,935.00
             14706.25,935.00 14652.59,1240.00 14652.59,1240.00
             14652.59,1240.00 14640.08,1311.00 14640.08,1311.00
             14640.08,1311.00 14637.42,1326.00 14637.42,1326.00
             14637.07,1327.86 14636.83,1330.75 14635.26,1332.01
             14633.71,1333.26 14629.95,1333.00 14628.00,1333.00
             14628.00,1333.00 14526.00,1333.00 14526.00,1333.00 Z
           M 5993.00,1229.00
           C 5993.00,1229.00 6279.00,1229.00 6279.00,1229.00
             6279.00,1229.00 6290.00,1228.09 6290.00,1228.09
             6328.73,1225.43 6373.92,1215.88 6404.00,1189.71
             6425.41,1171.09 6433.21,1146.34 6435.09,1119.00
             6435.09,1119.00 6435.96,1111.00 6435.96,1111.00
             6436.18,1103.98 6433.62,1089.88 6431.56,1083.00
             6426.81,1067.11 6416.16,1050.09 6404.91,1038.00
             6376.37,1007.31 6336.02,994.45 6296.00,986.45
             6296.00,986.45 6266.00,980.79 6266.00,980.79
             6266.00,980.79 6220.00,968.66 6220.00,968.66
             6151.79,946.86 6091.69,903.10 6064.45,835.00
             6050.35,799.76 6045.56,761.72 6046.00,724.00
             6046.00,724.00 6046.91,714.00 6046.91,714.00
             6051.16,653.26 6082.00,593.87 6127.00,553.17
             6160.99,522.42 6195.54,503.21 6238.00,486.81
             6307.31,460.04 6379.28,464.00 6452.00,464.00
             6452.00,464.00 6747.00,464.00 6747.00,464.00
             6747.00,464.00 6741.61,496.00 6741.61,496.00
             6741.61,496.00 6731.75,550.00 6731.75,550.00
             6731.75,550.00 6728.61,567.00 6728.61,567.00
             6728.23,569.03 6727.93,572.67 6726.26,574.01
             6724.71,575.26 6720.95,575.00 6719.00,575.00
             6719.00,575.00 6534.00,575.00 6534.00,575.00
             6534.00,575.00 6451.00,575.00 6451.00,575.00
             6451.00,575.00 6430.00,576.84 6430.00,576.84
             6396.18,580.45 6360.34,590.56 6343.12,623.00
             6339.52,629.78 6338.06,634.58 6336.42,642.00
             6335.53,646.08 6333.87,659.13 6334.09,663.00
             6334.50,670.28 6336.15,679.46 6339.37,686.00
             6347.26,701.99 6367.96,715.45 6384.00,722.42
             6415.51,736.12 6433.58,739.79 6466.00,747.89
             6466.00,747.89 6523.00,763.72 6523.00,763.72
             6545.21,770.38 6567.10,778.14 6588.00,788.26
             6644.80,815.75 6695.27,860.09 6723.73,917.00
             6732.63,934.81 6739.06,953.72 6743.87,973.00
             6747.77,988.61 6751.98,1014.09 6752.00,1030.00
             6752.00,1030.00 6752.00,1060.00 6752.00,1060.00
             6751.96,1084.67 6743.95,1118.91 6735.19,1142.00
             6726.69,1164.42 6714.93,1186.17 6700.00,1205.00
             6685.91,1222.77 6675.47,1233.32 6658.00,1247.75
             6635.91,1266.00 6610.97,1280.41 6585.00,1292.31
             6532.83,1316.20 6477.87,1329.08 6421.00,1335.16
             6421.00,1335.16 6392.00,1338.00 6392.00,1338.00
             6392.00,1338.00 6383.00,1338.00 6383.00,1338.00
             6383.00,1338.00 6373.00,1339.00 6373.00,1339.00
             6373.00,1339.00 6353.00,1339.00 6353.00,1339.00
             6353.00,1339.00 6337.00,1340.00 6337.00,1340.00
             6337.00,1340.00 6239.00,1340.00 6239.00,1340.00
             6239.00,1340.00 6122.00,1340.00 6122.00,1340.00
             6122.00,1340.00 5973.00,1340.00 5973.00,1340.00
             5973.00,1340.00 5980.39,1298.00 5980.39,1298.00
             5980.39,1298.00 5993.00,1229.00 5993.00,1229.00 Z
           M 15595.00,1229.00
           C 15595.00,1229.00 15881.00,1229.00 15881.00,1229.00
             15881.00,1229.00 15892.00,1228.09 15892.00,1228.09
             15930.93,1225.41 15975.76,1216.05 16006.00,1189.71
             16027.24,1171.22 16035.19,1146.15 16037.09,1119.00
             16037.09,1119.00 16037.96,1111.00 16037.96,1111.00
             16038.18,1103.98 16035.62,1089.88 16033.56,1083.00
             16028.81,1067.11 16018.16,1050.09 16006.91,1038.00
             15978.27,1007.21 15938.15,994.48 15898.00,986.45
             15898.00,986.45 15868.00,980.79 15868.00,980.79
             15868.00,980.79 15822.00,968.66 15822.00,968.66
             15756.95,947.87 15699.41,907.38 15670.31,844.00
             15653.56,807.52 15647.54,764.85 15648.00,725.00
             15648.15,712.32 15650.83,694.47 15653.58,682.00
             15674.91,585.27 15751.97,519.06 15842.00,486.05
             15913.66,459.78 15982.36,464.00 16057.00,464.00
             16057.00,464.00 16349.00,464.00 16349.00,464.00
             16349.00,464.00 16343.61,496.00 16343.61,496.00
             16343.61,496.00 16333.75,550.00 16333.75,550.00
             16333.75,550.00 16330.61,567.00 16330.61,567.00
             16330.23,569.03 16329.93,572.67 16328.26,574.01
             16326.71,575.26 16322.95,575.00 16321.00,575.00
             16321.00,575.00 16136.00,575.00 16136.00,575.00
             16136.00,575.00 16053.00,575.00 16053.00,575.00
             16053.00,575.00 16032.00,576.84 16032.00,576.84
             15998.17,580.45 15962.36,590.52 15945.12,623.00
             15941.52,629.78 15940.06,634.58 15938.42,642.00
             15937.53,646.08 15935.87,659.13 15936.09,663.00
             15936.56,671.25 15938.40,680.69 15942.32,688.00
             15949.98,702.25 15971.28,716.02 15986.00,722.42
             16017.46,736.11 16035.65,739.81 16068.00,747.89
             16068.00,747.89 16125.00,763.72 16125.00,763.72
             16147.21,770.38 16169.10,778.14 16190.00,788.26
             16246.80,815.75 16297.27,860.09 16325.73,917.00
             16335.07,935.68 16342.02,955.65 16346.65,976.00
             16350.08,991.06 16353.98,1014.76 16354.00,1030.00
             16354.00,1030.00 16354.00,1061.00 16354.00,1061.00
             16353.96,1085.83 16345.64,1119.77 16336.81,1143.00
             16291.56,1262.01 16164.63,1315.31 16047.00,1332.27
             16026.76,1335.19 16006.40,1337.15 15986.00,1338.04
             15986.00,1338.04 15973.00,1339.00 15973.00,1339.00
             15973.00,1339.00 15955.00,1339.00 15955.00,1339.00
             15955.00,1339.00 15939.00,1340.00 15939.00,1340.00
             15939.00,1340.00 15842.00,1340.00 15842.00,1340.00
             15842.00,1340.00 15724.00,1340.00 15724.00,1340.00
             15724.00,1340.00 15575.00,1340.00 15575.00,1340.00
             15575.00,1340.00 15582.39,1298.00 15582.39,1298.00
             15582.39,1298.00 15595.00,1229.00 15595.00,1229.00 Z
           M 4208.00,536.14
           C 4208.00,536.14 4190.00,538.42 4190.00,538.42
             4164.99,542.31 4140.55,549.63 4118.00,561.26
             4095.31,572.96 4074.63,588.58 4056.00,605.91
             3996.58,661.18 3961.46,738.53 3944.79,817.00
             3939.56,841.61 3936.62,865.96 3934.91,891.00
             3934.91,891.00 3934.00,901.00 3934.00,901.00
             3934.00,901.00 3934.00,954.00 3934.00,954.00
             3934.00,954.00 3934.91,966.00 3934.91,966.00
             3934.91,966.00 3937.85,1001.00 3937.85,1001.00
             3945.03,1061.08 3963.24,1121.24 4007.00,1165.00
             4018.56,1176.56 4025.35,1182.57 4039.00,1191.67
             4056.84,1203.56 4072.71,1210.72 4093.00,1217.33
             4112.72,1223.76 4133.41,1226.67 4154.00,1228.09
             4154.00,1228.09 4164.00,1229.00 4164.00,1229.00
             4194.83,1229.36 4224.58,1227.01 4254.00,1216.92
             4288.09,1205.23 4316.71,1186.55 4343.00,1162.09
             4404.94,1104.46 4436.67,1021.92 4450.41,940.00
             4453.92,919.08 4456.12,898.15 4457.04,877.00
             4457.04,877.00 4458.00,866.00 4458.00,866.00
             4458.00,866.00 4458.00,816.00 4458.00,816.00
             4458.00,816.00 4457.00,796.00 4457.00,796.00
             4457.00,796.00 4456.09,786.00 4456.09,786.00
             4456.09,786.00 4450.41,737.00 4450.41,737.00
             4439.00,668.96 4415.29,602.78 4353.00,565.40
             4338.03,556.42 4323.73,550.52 4307.00,545.71
             4289.83,540.77 4262.75,536.03 4245.00,536.14
             4245.00,536.14 4208.00,536.14 4208.00,536.14 Z
           M 13810.00,536.14
           C 13810.00,536.14 13792.00,538.42 13792.00,538.42
             13769.55,541.91 13746.49,548.34 13726.00,558.26
             13626.57,606.38 13568.94,712.74 13546.79,817.00
             13541.57,841.54 13538.62,866.02 13536.91,891.00
             13536.91,891.00 13536.00,901.00 13536.00,901.00
             13536.00,901.00 13536.00,954.00 13536.00,954.00
             13536.00,954.00 13536.91,966.00 13536.91,966.00
             13536.91,966.00 13539.85,1001.00 13539.85,1001.00
             13547.02,1061.07 13565.25,1121.25 13609.00,1165.00
             13620.02,1176.02 13626.98,1182.28 13640.00,1191.00
             13657.68,1202.84 13674.83,1210.75 13695.00,1217.33
             13714.72,1223.76 13735.41,1226.67 13756.00,1228.09
             13756.00,1228.09 13766.00,1229.00 13766.00,1229.00
             13796.83,1229.36 13826.58,1227.01 13856.00,1216.92
             13970.44,1177.68 14032.48,1058.83 14051.41,946.00
             14055.11,924.00 14058.74,901.31 14059.00,879.00
             14059.00,879.00 14060.00,864.00 14060.00,864.00
             14060.00,864.00 14060.00,816.00 14060.00,816.00
             14060.00,816.00 14059.00,796.00 14059.00,796.00
             14059.00,796.00 14058.09,786.00 14058.09,786.00
             14058.09,786.00 14051.42,731.00 14051.42,731.00
             14039.45,664.60 14015.76,601.85 13955.00,565.40
             13941.06,557.03 13925.65,550.31 13910.00,545.86
             13892.74,540.95 13864.82,536.03 13847.00,536.14
             13847.00,536.14 13810.00,536.14 13810.00,536.14 Z
           M 1840.00,1023.00
           C 1840.00,1023.00 1836.27,996.00 1836.27,996.00
             1836.27,996.00 1829.27,946.00 1829.27,946.00
             1829.27,946.00 1809.87,807.00 1809.87,807.00
             1807.14,787.35 1805.54,755.13 1784.00,746.48
             1777.24,743.76 1770.14,743.92 1763.00,744.00
             1752.77,744.12 1741.15,747.17 1733.00,753.53
             1721.86,762.22 1709.73,785.04 1703.25,798.00
             1703.25,798.00 1663.26,875.00 1663.26,875.00
             1663.26,875.00 1625.69,948.00 1625.69,948.00
             1625.69,948.00 1599.75,998.00 1599.75,998.00
             1595.92,1005.62 1589.55,1014.74 1588.00,1023.00
             1588.00,1023.00 1840.00,1023.00 1840.00,1023.00 Z
           M 11442.00,1023.00
           C 11442.00,1023.00 11439.28,1003.00 11439.28,1003.00
             11439.28,1003.00 11432.28,953.00 11432.28,953.00
             11432.28,953.00 11411.87,807.00 11411.87,807.00
             11409.13,787.25 11407.73,755.36 11386.00,746.48
             11379.64,743.88 11372.74,743.92 11366.00,744.00
             11355.60,744.13 11344.55,746.64 11336.00,752.79
             11324.37,761.15 11312.29,783.95 11305.75,797.00
             11305.75,797.00 11264.26,877.00 11264.26,877.00
             11264.26,877.00 11236.26,931.00 11236.26,931.00
             11236.26,931.00 11226.69,950.00 11226.69,950.00
             11226.69,950.00 11200.75,1000.00 11200.75,1000.00
             11197.25,1007.00 11191.38,1015.66 11190.00,1023.00
             11190.00,1023.00 11442.00,1023.00 11442.00,1023.00 Z
           M 385.00,1203.00
           C 385.00,1203.00 580.00,1203.00 580.00,1203.00
             591.63,1202.98 608.70,1200.94 620.00,1198.11
             641.00,1192.83 661.25,1181.98 678.00,1168.39
             728.18,1127.67 753.83,1064.35 764.08,1002.00
             764.08,1002.00 768.09,962.00 768.09,962.00
             768.09,962.00 769.00,952.00 769.00,952.00
             769.59,900.89 764.05,837.53 731.33,796.00
             710.13,769.09 676.21,753.05 642.00,753.00
             642.00,753.00 599.00,753.00 599.00,753.00
             599.00,753.00 464.00,753.00 464.00,753.00
             464.00,753.00 456.25,799.00 456.25,799.00
             456.25,799.00 438.25,901.00 438.25,901.00
             438.25,901.00 385.00,1203.00 385.00,1203.00 Z
           M 9987.00,1203.00
           C 9987.00,1203.00 10182.00,1203.00 10182.00,1203.00
             10193.63,1202.98 10210.70,1200.94 10222.00,1198.11
             10239.97,1193.59 10257.00,1185.19 10272.00,1174.42
             10327.45,1134.61 10355.32,1067.47 10366.08,1002.00
             10366.08,1002.00 10370.09,962.00 10370.09,962.00
             10370.09,962.00 10371.00,952.00 10371.00,952.00
             10371.60,900.80 10366.10,837.60 10333.33,796.00
             10312.11,769.07 10278.24,753.05 10244.00,753.00
             10244.00,753.00 10201.00,753.00 10201.00,753.00
             10201.00,753.00 10066.00,753.00 10066.00,753.00
             10066.00,753.00 10057.25,805.00 10057.25,805.00
             10057.25,805.00 10039.42,906.00 10039.42,906.00
             10039.42,906.00 10003.42,1110.00 10003.42,1110.00
             10003.42,1110.00 9987.00,1203.00 9987.00,1203.00 Z
           M 7921.91,772.09
           C 7916.93,772.90 7910.49,777.01 7906.00,779.58
             7906.00,779.58 7878.00,796.98 7878.00,796.98
             7859.64,809.63 7841.31,825.08 7827.89,843.00
             7807.45,870.30 7793.63,913.06 7790.92,947.00
             7790.92,947.00 7790.00,957.00 7790.00,957.00
             7790.00,957.00 7790.00,983.00 7790.00,983.00
             7790.33,1011.12 7802.16,1049.42 7813.70,1075.00
             7840.70,1134.88 7885.25,1183.01 7943.00,1214.31
             7951.77,1219.06 7959.62,1223.03 7969.00,1226.58
             7989.31,1234.24 8010.42,1238.78 8032.00,1240.96
             8032.00,1240.96 8042.00,1240.96 8042.00,1240.96
             8042.00,1240.96 8059.00,1242.00 8059.00,1242.00
             8059.00,1242.00 8069.00,1241.09 8069.00,1241.09
             8090.67,1239.57 8111.94,1235.15 8132.00,1226.57
             8151.74,1218.13 8169.82,1206.48 8188.00,1195.19
             8188.00,1195.19 8216.00,1176.33 8216.00,1176.33
             8218.59,1174.60 8224.65,1171.29 8224.89,1167.96
             8225.08,1165.39 8221.93,1161.95 8220.42,1160.00
             8220.42,1160.00 8207.42,1143.00 8207.42,1143.00
             8207.42,1143.00 8156.21,1076.00 8156.21,1076.00
             8156.21,1076.00 8079.12,975.00 8079.12,975.00
             8079.12,975.00 7988.87,857.00 7988.87,857.00
             7988.87,857.00 7941.42,795.00 7941.42,795.00
             7937.23,789.61 7927.44,774.28 7921.91,772.09 Z"
      />
    </chakra.svg>
  )
}

export default Logo
