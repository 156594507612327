module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Jaen App","short_name":"Jaen","start_url":"/","background_color":"#f7f0eb","theme_color":"#a2466c","display":"standalone","icon":"src/favicon.ico","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"55fd7dca30b5c92bada01f191548adc6"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-M58K75M9PG"],"gtagConfig":{"anonymize_ip":true},"pluginConfig":{"head":true,"respectDNT":false,"exclude":[],"origin":"https://www.googletagmanager.com","delayOnRouteUpdate":0}},
    },{
      plugin: require('../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"sampleRate":1,"enableTracing":true,"debug":true,"tracesSampleRate":1,"replaysSessionSampleRate":1,"replaysOnErrorSampleRate":1,"dsn":"https://54a9a5aade750c06d1d703087f5ce690@sentry.cronit.io/2"},
    },{
      plugin: require('../node_modules/gatsby-plugin-jaen/gatsby-browser.tsx'),
      options: {"plugins":[],"pylonUrl":"https://pylons.cronit.io/jaen/graphql","remote":{"repository":"ballonsballons-at/ballons-jaen"},"zitadel":{"organizationId":"270250197911609348","clientId":"270250453697110020@website","authority":"https://accounts2.cronit.io","redirectUri":"https://ballons-ballons.at","projectIds":["270250070505431044"]},"googleAnalytics":{"trackingIds":["G-M58K75M9PG"]},"sentry":{"org":"cronit","project":"ballons-ballons","dsn":"https://54a9a5aade750c06d1d703087f5ce690@sentry.cronit.io/2"}},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
